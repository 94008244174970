import React from 'react'
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import { AccountButtonDarkLink, AccountButtonLight, AccountButtonLightLink, LeftPadContainer } from "./AccountStyles"
import CtaButton from "src/components/Core/CtaButton"

const Info = styled.p`
  ${fonts.openSansRegular};
  font-size: 0.889rem;
  line-height: 1.5;
  color: #8c8984;
  margin: 0;
`

const LinkWrapper = styled.div`
  margin-top: 2.5rem;
  width: 100%;
  max-width: ${breakpoints.sm};
  @media (min-width: ${breakpoints.md}) {
    width: auto;
    max-width: unset;
    a {
      width: auto;
    }
  }
`
const Orders = styled.div``
const OrderCard = styled.div`
  background: #fffefc;
  padding: 1rem 1.5rem;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  border-radius: 0.25rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  @media (min-width: ${breakpoints.md}){
    margin-bottom: 1.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 1rem;
    width: 100%;
  }
`

const OrderImages = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.full ? "repeat(1, minmax(0, 1fr))" : "repeat(2, minmax(0, 1fr))"};
  gap: 1rem;
  margin-bottom: 1rem;
  @media (min-width: ${breakpoints.md}) {
    margin: 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

const ImageWrapper = styled.div`
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0;
`
const OrderImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  display: block;
`
const OrderInfos = styled.div`
  ${fonts.openSansRegular};
  line-height: 1.5;
  color: #1c1a17;
`

const MobileTitle = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const DesktopTitle = styled.div`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
    svg {
      width: 0.75rem;
      height: 0.75rem;
      fill: ${(props) =>
        props.red ? "#e61c1c" : props.green ? "#3d995e" : "#8c8984"};
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
`

const OrderTitle = styled.h2`
  margin-bottom: 0.75rem;
  font-size: 1.602rem;
  line-height: 1.25;
  ${fonts.openSansBold};
  color: #090602;
  @media (min-width: ${breakpoints.md}) {
    font-size: 1.266rem;
    display: inline-block;
  }
`
const OrderInfo = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.79rem;
  line-height: inherit;
`
const Label = styled.span`
  padding-right: 0.5rem;
  display: inline-block;
`
const Copy = styled.span`
  font-weight: 600;
`
const Status = styled.div`
  margin-bottom: 2.5rem;
  svg {
    width: 0.5rem;
    height: 0.5rem;
    fill: ${(props) =>
      props.red ? "#e61c1c" : props.green ? "#3d995e" : "#8c8984"};
    display: inline-block;
    margin-right: 0.25rem;
  }
  span {
    font-size: 0.889rem;
  }
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const OrderList = ({orders, setSelectedOrder}) => {
  return (
    <LeftPadContainer>
      {orders.length > 0 ? (
        <Orders>
          {orders.map((order, index) => {
            const { id, items, number, grand_total, date_created, status } =
              order
            const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "long",
              day: "2-digit",
            })
            const [{ value: month }, , { value: day }, , { value: year }] =
              dateTimeFormat.formatToParts(new Date(date_created))
            const formattedDate = `${day} ${month} ${year}`

            let statusInfo = status
            if (status && typeof status === "string") {
              statusInfo = status.replace("_", " ")
            }

            const titleStatus =
              statusInfo.slice(0, 1).toUpperCase() + statusInfo.slice(1)

            return (
              <OrderCard key={id}>
                <OrderImages full={items.length === 1 ? 1 : 0}>
                  {items.map((item, index) => {
                    const { product } = item
                    if (product?.images && product.images.length > 0) {
                      const imageFile = product.images[0].file
                      return (
                        <ImageWrapper key={index}>
                          <OrderImage src={imageFile.url} alt={product.name} />
                        </ImageWrapper>
                      )
                    }
                  })}
                </OrderImages>
                <OrderInfos>
                  <MobileTitle>
                    <OrderTitle>{`Order #${number}`}</OrderTitle>
                  </MobileTitle>
                  <DesktopTitle
                    green={status === "complete"}
                    red={status === "canceled"}
                  >
                    <OrderTitle>{titleStatus}</OrderTitle>
                    <svg data-v-7e8964de="" fill="none" viewBox="0 0 10 10">
                      <circle data-v-7e8964de="" cx="5" cy="5" r="5"></circle>
                    </svg>
                  </DesktopTitle>

                  <OrderInfo>
                    <Label>Order date</Label>
                    <Copy>{formattedDate}</Copy>
                  </OrderInfo>
                  <OrderInfo>
                    <Label>Order</Label>
                    <Copy>{`#${number}`}</Copy>
                  </OrderInfo>
                  <OrderInfo>
                    <Label>Total</Label>
                    <Copy>{`$${grand_total.toFixed(2)}`}</Copy>
                  </OrderInfo>
                  <Status
                    green={status === "complete"}
                    red={status === "canceled"}
                  >
                    <svg data-v-7e8964de="" fill="none" viewBox="0 0 10 10">
                      <circle data-v-7e8964de="" cx="5" cy="5" r="5"></circle>
                    </svg>
                    <span>{`Your order is ${statusInfo}.`}</span>
                  </Status>
                  <AccountButtonLight onClick={() => setSelectedOrder(order)}>
                    view order
                  </AccountButtonLight>
                </OrderInfos>
              </OrderCard>
            )
          })}
        </Orders>
      ) : (
        <>
          <Info>You haven't ordered anything yet.</Info>
          <LinkWrapper>
            <CtaButton
              url="https://www.eatigc.com/shop"
              label={"start shopping"}
              blue
            />
          </LinkWrapper>
        </>
      )}
    </LeftPadContainer>
  )
}

export default OrderList
