import React from "react"
import Seo from "../../components/Core/Seo"
import Layout from "../../components/Layout/Layout"
import colors from "../../styles/colors"
import Orders from "../../components/AccountContent/Orders"
import AccountNavigation from "../../components/AccountContent/AccountNavigation"

const OrdersPage = ({ location }) => {
  const path = location.pathname

  return (
    <Layout dark bg={colors.accountGrey}>
      <Seo
        title="Orders Page"
        description="In Good Company Orders Page"
        fullTitle="Orders Page"
        path={path}
      />
      <AccountNavigation path={path}>
        <Orders/>
      </AccountNavigation>
    </Layout>
  )
}

export default OrdersPage
