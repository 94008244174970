import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import { ReactComponent as Arrow } from "../../resources/img/svg/arrow-left.svg"
import colors from "../../styles/colors"
import {
  Amex,
  Diners,
  Discover,
  UnionPay,
  Generic,
  Jcb,
  Mastercard,
  Visa,
  Amazon,
  Paypal,
} from "react-pay-icons"

const OrderContainer = styled.div`
  width: 100%;
`

const Container = styled.div`
  padding: 0 1.5rem;
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  @media (min-width: ${breakpoints.md}) {
    margin: 0;
    max-width: unset;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 2rem;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 0 2.5rem;
  }
`

const BackBtn = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
  margin-bottom: 1.5rem;
  span {
    margin-left: 0.25rem;
    ${fonts.openSansMedium};
    font-size: 0.889rem;
    line-height: 1.5;
    color: #1c1a17;
    display: inline-block;
  }
  &:hover {
    span {
      color: ${colors.royalBlue};
    }
    svg path {
      fill: ${colors.royalBlue} !important;
    }
  }
`
const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  width: 1.25rem;
  svg {
    height: 0.6rem;
  }
`

const DetailsContent = styled.div`
  padding-bottom: 1.5rem;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid #d9d5d0;
`
const Title = styled.h2`
  font-size: 1.424rem;
  line-height: 1.25;
  ${fonts.openSansBold};
  margin-bottom: 0.75rem;
  color: #090602;
`
const Subtitle = styled.div`
  margin: 1rem 0;
  svg {
    width: 0.5rem;
    height: 0.5rem;
    fill: ${(props) =>
      props.red ? "#e61c1c" : props.green ? "#3d995e" : "#8c8984"};
    display: inline-block;
    margin-right: 0.25rem;
  }
  span {
    ${fonts.openSansBold};
    font-size: 1rem;
    line-height: 1.5;
    color: #1c1a17;
  }
`

const BorderedBlock = styled.div`
  border: 1px solid #d9d5d0;
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
`

const Info = styled.div`
  padding-bottom: 0.5rem;
`

const Copy = styled.span`
  ${fonts.openSansRegular};
  font-size: 0.889rem;
  line-height: 1.5;
  color: #1c1a17;
`
const BoldCopy = styled(Copy)`
  font-weight: 600;
`

const Summary = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  font-size: 1rem;
  line-height: 1.5;
  ${fonts.openSansMedium};
  color: #1c1a17;
`

const Items = styled.div`
  margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media (min-widh: ${breakpoints.md}) {
    gap: 1rem;
  }
  @media (min-widh: ${breakpoints.lg}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

const Item = styled.div`
  padding: 1.5rem 0;
  border-bottom: 1px solid #d9d5d0;
  display: flex;
`
const ItemImageBlock = styled.div`
  min-width: 6.5rem;
  margin-right: 1.5rem;
`
const ItemTextBlock = styled.div`
  h4 {
    margin: 0;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #090602;
    ${fonts.openSansBold};
  }
  p {
    margin: 0;
    padding-top: 0.5rem;
    font-size: 0.889rem;
    line-height: 1.5;
    ${fonts.openSansMedium};
  }
`

const ItemImage = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: block;
    vertical-align: middle;
  }
`

const PriceRow = styled(Summary)`
  padding: 0 0 0.5rem;
  ${fonts.openSansRegular};
`
const TotalRow = styled(Summary)`
  padding: 0;
  font-size: 1.125rem;
`

const BlockTitle = styled.p`
  padding-bottom: 1rem;
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  ${fonts.openSansMedium};
  color: #1c1a17;
`
const BlockWrapper = styled.div`
  margin-bottom: 2.5rem;
`

const DetailBlock = styled.div`
  padding: 1rem;
  border: 1px solid #d9d5d0;
  border-radius: 0.25rem;
`

const DetailCopy = styled.p`
  ${fonts.openSansRegular};
  font-size: 0.889rem;
  line-height: 1.5;
  color: #1c1a17;
`
const DetailTitle = styled(DetailCopy)`
  ${fonts.openSansMedium};
  padding-bottom: 0.5rem;
`

const PaymentRow = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  ${fonts.openSansRegular};
  font-size: 0.889rem;
  line-height: 1.5;
  color: #1c1a17;
`
const PaymentImage = styled.div``

const DetailCopyLarge = styled(DetailCopy)`
  font-size: 1rem;
`
const DotsContainer = styled.div`
  display: flex;
  white-space: pre-wrap;
`

const Dots = styled.span`
  letter-spacing: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1c1a17;
  ${fonts.openSansRegular};
`

const OrderDetails = ({ order, setSelectedOrder }) => {
  let statusInfo = order.status
  if (order.status && typeof order.status === "string") {
    statusInfo = order.status.replace("_", " ")
  }

  const titleStatus = statusInfo.slice(0, 1).toUpperCase() + statusInfo.slice(1)
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  })
  const [{ value: month }, , { value: day }, , { value: year }] =
    dateTimeFormat.formatToParts(new Date(order.date_created))
  const formattedDate = `${day} ${month} ${year}`
  const { shipping, billing } = order

  let shortExpYear

  if(billing?.card?.exp_year) {
    shortExpYear = billing.card.exp_year.toString().slice(-2)
  }

  let paymentName = ""
  if (billing.method) {
    if (billing.method !== "card") {
      paymentName =
        billing.method.slice(0, 1).toUpperCase() + billing.method.slice(1)
    } else {
      paymentName = billing.card.brand
    }
  }

  const getPaymentImage = (method, brand) => {
    const styleObj = { width: 50 }

    if (method === "amazon") {
      return <Amazon style={styleObj} />
    }

    if (method === "paypal") {
      return <Paypal style={styleObj} />
    }

    if (method === "account") {
      return <Generic style={styleObj} />
    }

    switch (brand) {
      case "American Express":
        return <Amex style={styleObj} />
      case "Diners Club":
        return <Diners style={styleObj} />
      case "Discover":
        return <Discover style={styleObj} />
      case "JCB":
        return <Jcb style={styleObj} />
      case "MasterCard":
        return <Mastercard style={styleObj} />
      case "UnionPay":
        return <UnionPay style={styleObj} />
      case "Visa":
        return <Visa style={styleObj} />
      default:
        return <Generic style={styleObj} />
    }
  }

  return (
    <OrderContainer>
      <Container>
        <BackBtn onClick={() => setSelectedOrder(null)}>
          <ArrowWrapper>
            <Arrow viewBox="0 0 10 15" />
          </ArrowWrapper>
          <span>Back to all orders</span>
        </BackBtn>
      </Container>
      <Container>
        <DetailsContent>
          <Title>{`Order #${order.number}`}</Title>
          <Subtitle
            green={order.status === "complete"}
            red={order.status === "canceled"}
          >
            <svg data-v-7e8964de="" fill="none" viewBox="0 0 10 10">
              <circle data-v-7e8964de="" cx="5" cy="5" r="5"></circle>
            </svg>
            <span>{titleStatus}</span>
          </Subtitle>
          <BorderedBlock>
            <Info>
              <Copy>Order date</Copy>
              <BoldCopy>{` ${formattedDate}`}</BoldCopy>
            </Info>
            <Info>
              <Copy>Total</Copy>
              <BoldCopy>{` $${order.grand_total.toFixed(2)}`}</BoldCopy>
            </Info>
          </BorderedBlock>
          <Summary>
            <span>Order summary</span>
            <span>{`${order.item_quantity} ${
              order.item_quantity === 1 ? "item" : "items"
            }`}</span>
          </Summary>
          <Items>
            {order.items.map((item) => {
              const {
                id,
                price_total,
                quantity,
                product: { images, name },
                variant
              } = item

              return (
                <Item key={id}>
                  <ItemImageBlock>
                    {images && images[0] && (
                      <ItemImage>
                        <img src={images[0].file.url} alt={name} />
                      </ItemImage>
                    )}
                  </ItemImageBlock>
                  <ItemTextBlock>
                    <h4>{name}</h4>
                    {quantity > 1 && <p>{`Qty: ${quantity}`}</p>}
                    {variant?.name && <p>{`Option: ${variant.name}`}</p>}
                    <p>{`$${price_total.toFixed(2)}`}</p>
                  </ItemTextBlock>
                </Item>
              )
            })}
          </Items>
          <PriceRow>
            <span>Subtotal</span>
            <span>{`$${order.sub_total.toFixed(2)}`}</span>
          </PriceRow>
          <PriceRow>
            <span>Shipping</span>
            <span>{`$${order.shipment_price.toFixed(2)}`}</span>
          </PriceRow>
          <TotalRow>
            <span>Total</span>
            <span>{`$${order.grand_total.toFixed(2)}`}</span>
          </TotalRow>
        </DetailsContent>
      </Container>
      <Container>
        <BlockTitle>Delivery details</BlockTitle>
        <BlockWrapper>
          <DetailBlock>
            <DetailTitle>Delivery address</DetailTitle>
            <DetailCopy>
              {shipping.name}
              <br />
              {`${shipping.address1}, ${shipping.city} ${
                shipping.zip && `, ${shipping.zip}`
              }`}
              <br />
              {shipping.state
                ? `${shipping.state} ${shipping.country}`
                : shipping.country}
            </DetailCopy>
          </DetailBlock>
          <DetailBlock>
            <DetailTitle>Phone number</DetailTitle>
            {shipping.phone && <DetailCopy>{shipping.phone}</DetailCopy>}
          </DetailBlock>
          <DetailBlock>
            <DetailTitle>Delivery method</DetailTitle>
            <DetailCopy>{`${shipping.service_name} (${
              shipping.price ? `$${shipping.price.toFixed(2)}` : "£0.00"
            })`}</DetailCopy>
          </DetailBlock>
        </BlockWrapper>
        <BlockWrapper>
          <BlockTitle>Payment details</BlockTitle>
          <DetailBlock>
            <PaymentRow>
              <PaymentImage>
                {getPaymentImage(billing.method, billing.card?.brand)}
              </PaymentImage>
              <DetailTitle>{paymentName}</DetailTitle>
            </PaymentRow>
            {billing.method === "card" && (
              <PaymentRow>
                <DotsContainer>
                  <Dots>···· ···· </Dots>
                  <DetailCopyLarge>{billing.card.last4}</DetailCopyLarge>
                </DotsContainer>
                <DetailCopyLarge>{`${billing.card.exp_month} / ${shortExpYear}`}</DetailCopyLarge>
              </PaymentRow>
            )}
            <DetailCopy>{billing.name}</DetailCopy>
          </DetailBlock>
          <DetailBlock>
            <DetailTitle>Billing address</DetailTitle>
            <DetailCopy>
              {billing.name}
              <br />
              {`${billing.address1}, ${billing.city}${
                billing.zip && `, ${billing.zip}`
              }`}
              <br />
              {billing.state
                ? `${billing.state} ${billing.country}`
                : billing.country}
            </DetailCopy>
          </DetailBlock>
        </BlockWrapper>
      </Container>
    </OrderContainer>
  )
}

export default OrderDetails
